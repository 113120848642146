export default function checkPasswordStrength(password) {
	let countStrength = 0;

	if (password.length >= 8) {
		countStrength += 1;
	}
	if (password.match(/[a-z]/) && password.match(/[A-Z]/)) {
		countStrength += 1;
	}
	if (password.match(/\d/)) {
		countStrength += 1;
	}
	if (password.match(/[^a-zA-Z\d]/)) {
		countStrength += 1;
	}

	return countStrength;
}
