import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
	errorMessageContainer: {
		backgroundColor: "#963131",
		color: "white",
		borderRadius: "4px",
		marginTop: "5px",
		padding: "10px",
		width: "calc(100% - 20px)",
	},
	infoStyle: {
		backgroundColor: "#355882",
		position: "relative",

		[theme.breakpoints.up("password")]: {
			position: "absolute",
			top: "-4px",
			width: "114px",
			left: "385px",
		},
	},
	errorMessage: {
		fontSize: "14px",
		lineHeight: "20px",
		letterSpacing: "-0.02em",
	},
}));

export const FieldError = ({ field, styleType = "error" }) => {
	const classes = useStyles();
	let message = "";

	switch (field) {
		case "password":
			message =
				"Your chosen password must be at least 8 characters long and contain at least one uppercase, one lowercase letter, a special character, and one number.";
			break;
		case "toc":
			message = "You must accept Privacy Policy and Terms of Service";
			break;
		case "generic":
			message =
				"Some of the required fields are missing or incorrect. Please review your data, then click Sign up to retry.";
			break;
		case "sign_up_error":
			message =
				"Sign up failed. Please use another email or try again later. Contact support@studio3t.com if you need help.";
			break;
		case "sign_in_error":
			message =
				"Your email address or password are incorrect. Please try again, or recover your password.";
			break;
		case "forgot_pass_error":
			message = "Please provide a valid email address.";
			break;
		case "forgot_pass_verification_error":
			message = "Please provide a valid verification code.";
			break;
		case "forgot_pass_passwords_not_matched":
			message = "Passwords do not match.";
			break;
		case "phone":
			message = "Invalid phone number format.";
			break;
		case "email":
			message =
				"Invalid email format or an account with the given email already exists.";
			break;
		default:
			message = "This field is required.";
	}

	return (
		<Box
			className={`${classes.errorMessageContainer} ${
				styleType === "info" && classes.infoStyle
			}`}
			sx={{
				marginTop:
					field === "sign_in_error" ||
					field === "generic" ||
					field === "aws" ||
					field === "forgot_pass_error" ||
					field === "forgot_pass_verification_error" ||
					field === "forgot_pass_passwords_not_matched"
						? "15px !important"
						: "inherit",
			}}
		>
			<Typography className={classes.errorMessage}>{message}</Typography>
		</Box>
	);
};
