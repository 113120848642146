import { useState, useEffect } from "react";
import { useStateContext } from "../../../context/StateContext";
import {
	Box,
	Typography,
	TextField,
	Link,
	Button,
	CircularProgress,
	Divider,
	OutlinedInput,
	InputAdornment,
	IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import makeStyles from "@mui/styles/makeStyles";
import { FieldError } from "../../../components";
import checkPasswordStrength from "../../../hooks/checkPasswordStrength";

const useStyles = makeStyles((theme) => ({
	heading: {
		fontWeight: 600,
		fontSize: "20px",
		lineHeight: "24px",
		paddingBottom: "20px",
	},
	pt15px: {
		paddingTop: "15px",
	},
	wrapper: {
		position: "relative",
	},
	buttonProgress: {
		color: theme.palette.primary.main,
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -4,
		marginLeft: -15,
	},
	divider: {
		width: "100%",
		paddingTop: "20px",
		paddingBottom: "20px",
	},
}));

function ForgotPassConfirmBlock({
	forgotPassObj,
	handleForgotPassObjChange,
	loading,
	handleSubmit,
	awsError,
}) {
	const { dispatch } = useStateContext();
	const classes = useStyles();
	const [showPassword, setShowPassword] = useState(false);
	const [showRepeatedPassword, setShowRepeatedPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleClickShowRepeatedPassword = () =>
		setShowRepeatedPassword((show) => !show);
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
	const [focusedPasswordInput, setFocusedPasswordInput] = useState(false);
	const [focusedRepeatedPasswordInput, setRepeatedPasswordInput] =
		useState(false);
	const [showPasswordAdvice, setShowPasswordAdvice] = useState(false);
	const [showRepeatedPasswordAdvice, setShowRepeatedPasswordAdvice] =
		useState(false);

	function handleHitEnter(e) {
		if (e.key === "Enter") {
			handleSubmit("FORGOT_PASS_VERIFICATION");
			e.preventDefault();
		}
	}

	useEffect(() => {
		if (
			!forgotPassObj.newPassword.showError &&
			focusedPasswordInput &&
			checkPasswordStrength(forgotPassObj.newPassword.value) !== 4
		) {
			setShowPasswordAdvice(true);
		} else {
			setShowPasswordAdvice(false);
		}
	}, [focusedPasswordInput, forgotPassObj.newPassword]);

	useEffect(() => {
		if (
			!forgotPassObj.newPasswordRepeated.showError &&
			focusedRepeatedPasswordInput &&
			checkPasswordStrength(forgotPassObj.newPasswordRepeated.value) !== 4
		) {
			setShowRepeatedPasswordAdvice(true);
		} else {
			setShowRepeatedPasswordAdvice(false);
		}
	}, [focusedRepeatedPasswordInput, forgotPassObj.newPasswordRepeated]);

	function renderPasswordError(advice, error) {
		if (advice) {
			return <FieldError field="password" styleType="info" />;
		}

		if (error) {
			return <FieldError field="password" />;
		}
	}

	return (
		<>
			<Typography className={classes.heading}>
				Reset your password
			</Typography>
			<Typography>Verification code *</Typography>
			<TextField
				fullWidth
				variant="outlined"
				size="small"
				error={forgotPassObj.verificationCode.showError}
				value={forgotPassObj.verificationCode.value}
				onKeyDown={(e) => handleHitEnter(e)}
				onChange={(e) =>
					handleForgotPassObjChange(
						e.currentTarget.value,
						"verificationCode"
					)
				}
				placeholder="Type verification code here"
			/>
			{forgotPassObj.verificationCode.showError && <FieldError />}
			<Typography className={classes.pt15px}>New password *</Typography>
			<Box sx={{ position: "relative", width: "100%" }}>
				<OutlinedInput
					autoComplete="new-password"
					fullWidth
					type={showPassword ? "text" : "password"}
					size="small"
					error={forgotPassObj.newPassword.showError}
					value={forgotPassObj.newPassword.value}
					onBlur={() => {
						setFocusedPasswordInput(false);
					}}
					onFocus={() => setFocusedPasswordInput(true)}
					onKeyDown={(e) => handleHitEnter(e)}
					onChange={(e) =>
						handleForgotPassObjChange(
							e.currentTarget.value,
							"newPassword"
						)
					}
					placeholder="Type your new password here"
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
								edge="end"
							>
								{showPassword ? (
									<VisibilityOff />
								) : (
									<Visibility />
								)}
							</IconButton>
						</InputAdornment>
					}
				/>
				{renderPasswordError(
					showPasswordAdvice,
					forgotPassObj.newPassword.showError
				)}
			</Box>
			<Typography className={classes.pt15px}>
				Enter new password again *
			</Typography>
			<Box sx={{ position: "relative", width: "100%" }}>
				<OutlinedInput
					autoComplete="new-password"
					fullWidth
					type={showRepeatedPassword ? "text" : "password"}
					size="small"
					error={forgotPassObj.newPasswordRepeated.showError}
					value={forgotPassObj.newPasswordRepeated.value}
					onBlur={() => {
						setRepeatedPasswordInput(false);
					}}
					onFocus={() => setRepeatedPasswordInput(true)}
					onKeyDown={(e) => handleHitEnter(e)}
					onChange={(e) =>
						handleForgotPassObjChange(
							e.currentTarget.value,
							"newPasswordRepeated"
						)
					}
					placeholder="Type your new password here again"
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								onClick={handleClickShowRepeatedPassword}
								onMouseDown={handleMouseDownPassword}
								edge="end"
							>
								{showRepeatedPassword ? (
									<VisibilityOff />
								) : (
									<Visibility />
								)}
							</IconButton>
						</InputAdornment>
					}
				/>
				{renderPasswordError(
					showRepeatedPasswordAdvice,
					forgotPassObj.newPasswordRepeated.showError
				)}
			</Box>

			<div className={classes.wrapper}>
				<Button
					disabled={loading}
					sx={{
						marginTop: "15px",
					}}
					variant={"contained"}
					onClick={() => {
						handleSubmit("FORGOT_PASS_VERIFICATION");
					}}
				>
					Send
				</Button>
				{loading && (
					<CircularProgress
						size={24}
						className={classes.buttonProgress}
					/>
				)}
			</div>
			{awsError && <FieldError field="forgot_pass_verification_error" />}
			{forgotPassObj.passwordsMatch.showError && (
				<FieldError field="forgot_pass_passwords_not_matched" />
			)}
			<Box className={classes.divider}>
				<Divider />
			</Box>
			<Typography>
				<Link
					onClick={() =>
						dispatch({
							type: "SET_VIEW",
							payload: "FORGOT_PASS",
						})
					}
				>
					Back to 'Forgot your password?'
				</Link>
			</Typography>
		</>
	);
}

export default ForgotPassConfirmBlock;
