/**
 *
 * @param {string} str Input string
 * @returns {string} Trimmed string
 */
export const formatWhitespaces = (str) => {
	if (!str) {
		return "";
	}
	const formattedStr = str.replace(/\s+(?=\s|$)/g, "").trim() || "";
	return formattedStr;
};
