import { useState, useEffect } from "react";
import { Hub, Auth } from "aws-amplify";

/**
 *
 * @returns {boolean | null}
 */

export default function AuthenticatedStatus() {
	const [isAuthenticated, setIsAuthenticated] = useState(null);

	async function ionViewCanEnter() {
		try {
			const authenticatedUser = await Auth.currentAuthenticatedUser();
			if (authenticatedUser !== undefined) {
				console.log('Find authenticatedUser -> Set isAuthenticated as true.');
				setIsAuthenticated(true);
			} else {
				console.log('Cannot find authenticatedUser -> Set isAuthenticated as false.');
				setIsAuthenticated(false);
			}
		} catch (error) {
			console.log('Got error when trying to fetch authenticatedUser -> Set isAuthenticated as false.');
			console.log(error);
			setIsAuthenticated(false);
		}
	}

	useEffect(() => {
		ionViewCanEnter();
	});

	useEffect(() => {
		const listener = (data) => {
			// eslint-disable-next-line default-case
			switch (data.payload.event) {
				case "signIn" || "autoSignIn" || "tokenRefresh":
					console.log(`${data.payload.event} -> Set isAuthenticated as true.`);
					setIsAuthenticated(true);
					break;
				case "signOut" ||
					"signIn_failure" ||
					"tokenRefresh_failure" ||
					"autoSignIn_failure":
					console.log(`${data.payload.event} -> Set isAuthenticated as false.`);
					setIsAuthenticated(false);
					break;
			}
		};

		const unsubscribe = Hub.listen("auth", listener);

		return () => {
			unsubscribe();
		};
	});

	return isAuthenticated;
}
