export const AUTH_STRINGS = {
	signIn: {
		challengeName: {
			newPassword: "NEW_PASSWORD_REQUIRED",
		},
		cognitoError: {
			errorMessage: {
				incorrectUsernameOrPassword: "Incorrect username or password.",
			},
		},
	},
	signUp: {
		cognitoError: {
			errorCode: {
				invalidParameter: "InvalidParameterException",
				userExists: "UsernameExistsException",
			},
			errorMessage: {
				invalidPhone: "Invalid phone number format.",
				invalidEmail: "Username should be an email.",
			},
		},
	},
};

export const QUERY_PARAMS_KEYS = {
	authCode: "authorization_code",
	challenge: "code_challenge",
	challengeMethod: "code_challenge_method",
	clientID: "client_id",
	forceAuth: "forceAuth",
	logoutURI: "logout_uri",
	originURI: "origin_uri",
	redirectURI: "redirect_uri",
	responseType: "response_type",
	state: "state",
};

export const STORAGE_KEYS = {
	authCode: "authorization_code",
	clientID: "client-id",
	debug: "debug",
	originURI: "origin_uri",
	redirectInfo: "redirectInfo",
	redirectURI: "client-redirect-uri",
	state: "client-state",
};
