import { useStateContext } from "../../../context/StateContext";
import {
	Box,
	Typography,
	TextField,
	Link,
	Button,
	CircularProgress,
	Divider,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { FieldError } from "../../../components";

const useStyles = makeStyles((theme) => ({
	pt15px: {
		paddingTop: "15px",
	},
	wrapper: {
		position: "relative",
	},
	buttonProgress: {
		color: theme.palette.primary.main,
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -4,
		marginLeft: -15,
	},
	divider: {
		width: "100%",
		paddingTop: "20px",
		paddingBottom: "20px",
	},
}));

function ForgotPassSendBlock({
	forgotPassObj,
	handleForgotPassObjChange,
	loading,
	handleSubmit,
	awsError,
}) {
	const { dispatch } = useStateContext();
	const classes = useStyles();

	function handleHitEnter(e) {
		if (e.key === "Enter") {
			handleSubmit("FORGOT_PASS");
			e.preventDefault();
		}
	}

	return (
		<>
			<Typography>
				Please enter your registered email address below. If it matches
				our records we will send you a message with a verification code.
			</Typography>
			<TextField
				autoComplete="email"
				fullWidth
				variant="outlined"
				size="small"
				placeholder="Type your email address here"
				className={classes.pt15px}
				value={forgotPassObj.email.value}
				onKeyDown={(e) => handleHitEnter(e)}
				onChange={(e) =>
					handleForgotPassObjChange(e.currentTarget.value, "email")
				}
			/>

			<div className={classes.wrapper}>
				<Button
					disabled={loading}
					sx={{
						marginTop: "15px",
					}}
					variant={"contained"}
					onClick={() => {
						handleSubmit("FORGOT_PASS");
					}}
				>
					Send reset link
				</Button>
				{loading && (
					<CircularProgress
						size={24}
						className={classes.buttonProgress}
					/>
				)}
			</div>
			{awsError && <FieldError field="forgot_pass_error" />}
			<Box className={classes.divider}>
				<Divider />
			</Box>
			<Typography>
				<Link
					onClick={() =>
						dispatch({
							type: "SET_VIEW",
							payload: "SIGN_IN",
						})
					}
				>
					Back to the sign in page
				</Link>
			</Typography>
		</>
	);
}

export default ForgotPassSendBlock;
