import { memo } from "react";

import { Link } from "@mui/material";

export const PolicyAndTermsBlock = memo(() => (
	<>
		I accept 3T Software Labs’{" "}
		<Link
			rel="noopener noreferrer"
			target="_blank"
			href="https://studio3t.com/privacy-policy/"
		>
			Privacy Policy
		</Link>{" "}
		and{" "}
		<Link
			rel="noopener noreferrer"
			target="_blank"
			href="https://files.studio3t.com/eula/eula.pdf"
		>
			Terms of Service
		</Link>{" "}
		*
	</>
));
