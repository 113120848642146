import React from "react";
import { green } from "@mui/material/colors";
import { Box, Container, Grid, Typography, Link } from "@mui/material";
import { CheckCircleOutlineOutlined as SuccessIcon } from "@mui/icons-material";

export const AppLogout = () => {
	return (
		<Container maxWidth="sm">
			<Grid
				container
				spacing={3}
				direction="column"
				alignItems="center"
				justifyContent="center"
				style={{
					minHeight: "100vh",
					height: "100vh",
				}}
			>
				<Box mb={2}>
					<SuccessIcon
						style={{
							fontSize: "60px",
							color: green[500],
						}}
					/>
				</Box>
				<Typography
					style={{
						width: "100%",
					}}
					variant="h6"
					gutterBottom
					align="center"
				>
					{"The registration for your 3T account was cancelled."}
				</Typography>
				<Typography>
					{"Back to "}
					<Link href="https://studio3t.com/">{"studio3t.com"}</Link>
				</Typography>
			</Grid>
		</Container>
	);
};
