import React from "react";
import { Box, Button, Typography, Modal } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
	modalContainer: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 400,
		backgroundColor: "#ffffff",
		border: "1px solid #DADADA",
		borderRadius: 4,
		boxShadow: 24,
		padding: 25,
		display: "flex",
		flexDirection: "column",
		gap: 20,
	},
	bottomContainer: {
		display: "flex",
		gap: 15,
	},
}));

export const CancelRegistrationModal = ({ isOpen, toggleOpen, onConfirm }) => {
	const classes = useStyles();
	return (
		<Modal
			open={isOpen}
			onClose={toggleOpen}
			aria-labelledby="Cancel registeration"
			aria-describedby="To complete your registration, please complete the following information"
		>
			<Box className={classes.modalContainer}>
				<Typography variant="h6" component="h2" fontWeight={600}>
					{"Would you like to cancel the sign up?"}
				</Typography>
				<Typography>
					{
						"By cancelling you will not gain access to the benefits of a 3T account and to the free and commercial editions of Studio 3T."
					}
				</Typography>
				<Box className={classes.bottomContainer}>
					<Button variant={"contained"} onClick={toggleOpen}>
						{"Continue sign up"}
					</Button>
					<Button variant={"outlined"} onClick={onConfirm}>
						{"Cancel sign up"}
					</Button>
				</Box>
			</Box>
		</Modal>
	);
};
