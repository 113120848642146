export default function Studio3TLogo() {
	return (
		<svg
			width="87"
			height="37"
			viewBox="0 0 87 37"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M54.4807 24.5686C55.918 26.31 58.5872 27.674 62.1951 27.674C66.8882 27.674 69.9095 25.3232 69.9095 21.9855C69.9095 19.0833 67.3282 17.545 65.3336 17.3419C67.5042 16.9646 69.6161 15.3683 69.6161 12.9304C69.6161 9.70885 66.8002 7.67725 62.2244 7.67725C58.7926 7.67725 56.3287 8.98328 54.7447 10.7537L56.798 13.3077C58.1766 12.0017 59.8779 11.2761 61.7845 11.2761C63.8377 11.2761 65.4216 12.0597 65.4216 13.5979C65.4216 15.0491 63.9844 15.7456 61.8138 15.7456C61.0511 15.7456 59.7312 15.7456 59.3792 15.7166V19.3154C59.6725 19.2864 60.9925 19.2864 61.8138 19.2864C64.5417 19.2864 65.7443 20.012 65.7443 21.5792C65.7443 23.0594 64.4243 24.0752 62.0484 24.0752C60.1418 24.0752 58.0006 23.2626 56.6513 21.8695L54.4807 24.5686Z"
				fill="#103B41"
			/>
			<path
				d="M76.2816 27.3258H80.4761V11.5953H86.1666V7.96747H70.5619V11.5953H76.2816V27.3258Z"
				fill="#103B41"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M49.0245 15.4732C48.6509 14.9588 48.288 14.4326 48.0065 13.8574C47.7561 13.3458 47.5705 12.7995 47.3848 12.2532C47.1582 11.5864 46.9315 10.9193 46.5869 10.315C42.0535 2.36517 33.9818 -0.0975953 26.1856 0.00293537C19.0333 0.0950074 7.25213 4.14645 1.95809 16.4413C1.61875 17.2475 1.32099 18.0729 1.07017 18.9134C0.682875 20.2098 0.397865 21.5454 0.21114 22.8967L0.203392 22.8955C-0.654965 31.4477 1.30418 35.8979 4.18714 36.801C6.11667 37.4055 7.81474 36.5901 9.80713 34.9329C11.9974 33.111 12.8554 31.7355 12.6097 29.8403C12.3557 27.8811 10.2183 26.4529 8.28437 25.9439C5.48655 25.1397 6.61384 23.3646 6.61384 23.3646C6.61384 23.3646 9.62761 25.5069 14.4883 24.9677C18.9227 24.4759 19.766 23.5596 19.766 23.5596C19.7593 19.0322 22.2149 15.8744 25.4242 15.5701C23.1942 16.6104 21.9061 20.2037 21.9105 23.1193L21.922 30.8363L23.0316 30.8379C26.2839 30.8426 29.0565 27.6063 29.0511 23.9475L29.0488 22.4322C29.0488 22.4322 31.4825 21.1265 34.9371 20.7433C36.5721 20.562 37.8515 20.7312 38.9563 21.0581C38.3136 20.2838 37.8983 19.3682 37.8631 18.2889C37.8477 17.8181 38.6346 17.6114 38.8073 18.2902C39.423 21.0261 41.0046 22.1972 44.96 23.3708C45.6291 23.4784 46.3641 23.5056 47.2023 23.4126C48.917 23.1795 50.6285 22.2402 50.9024 20.2455C51.1591 18.3775 50.2192 17.0992 49.2578 15.7915L49.2574 15.791L49.257 15.7905C49.1794 15.685 49.1017 15.5793 49.0245 15.4732ZM30.4811 23.1405C30.9251 27.4725 28.4372 29.3018 28.4372 29.3018C31.9087 29.0958 35.835 25.9591 35.6365 21.9299C35.6365 21.9299 34.0971 21.7087 30.4811 23.1405ZM43.3527 15.5825C43.969 15.5834 44.4678 15.0525 44.4669 14.3967C44.4659 13.7409 43.9655 13.2085 43.3492 13.2076C42.7329 13.2067 42.234 13.7376 42.235 14.3935C42.236 15.0493 42.7364 15.5817 43.3527 15.5825Z"
				fill="#17AF66"
			/>
		</svg>
	);
}
