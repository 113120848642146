import { QUERY_PARAMS_KEYS, STORAGE_KEYS } from "../const";

const getStringParams = () => {
	let redirect_uri;
	let authorization_code;
	let clientState;

	let queryStringParams = new URLSearchParams(window.location.search);
	let qsRedirectUri = queryStringParams.get(QUERY_PARAMS_KEYS.redirectURI);
	let qsAuthorizationCode = queryStringParams.get(QUERY_PARAMS_KEYS.authCode);
	let qsClientState = queryStringParams.get(QUERY_PARAMS_KEYS.state);
	let isDebug = localStorage.getItem(STORAGE_KEYS.debug);

	/*
	 * For a local sign in the redirect_uri/authorization_code will be in the query string params
	 */
	if (qsRedirectUri) {
		redirect_uri = qsRedirectUri;
		authorization_code = qsAuthorizationCode;
		clientState = qsClientState;
	} else {
		/*
		 * For a federated sign in the redirect_uri/authorization_code will be in the local storage
		 */
		redirect_uri = localStorage.getItem(STORAGE_KEYS.redirectURI);
		authorization_code = localStorage.getItem(STORAGE_KEYS.authCode);
		clientState = localStorage.getItem(STORAGE_KEYS.state);
	}

	return {
		redirect_uri,
		authorization_code,
		clientState,
		isDebug,
	};
};

export default getStringParams;
