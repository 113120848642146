import { LinearProgress, Grid, Typography } from "@mui/material";

const LoadingPage = ({ text }) => {
	return (
		<Grid
			container
			direction="column"
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
				backgroundColor: "#fafafa",
			}}
		>
			<Grid item>
				<Typography
					gutterBottom
					sx={{
						WebkitFontSmoothing: "antialiased",
						MozOsxFontSmoothing: "grayscale",
						fontFamily:
							"Inter, Helvetica Neue, Roboto, Arial, sans-serif",

						color: "rgba(0, 0, 0, 0.87)",
					}}
				>
					{text}
				</Typography>
			</Grid>
			<Grid item>
				<LinearProgress
					sx={{
						width: "168px",
						marginTop: "16px",
					}}
					color="primary"
				/>
			</Grid>
		</Grid>
	);
};

export default LoadingPage;
