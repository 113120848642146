import { useLayoutEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import {
	Box,
	Button,
	Grid,
	Link,
	Typography,
	useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
	useStateContext,
	setLoadingViewAction,
} from "../../context/StateContext";
import GoogleIcon from "../../assets/GoogleIcon";
import emptyUserObj from "../../placeholders/userobj";
import emptyForgotPassObj from "../../placeholders/forgotPasswordObj";
import SignInBlock from "./signInBlocks/signIn";
import SignUpBlock from "./signInBlocks/signUp";
import checkPasswordStrength from "../../hooks/checkPasswordStrength";
import handleIdpLogin from "../../hooks/handleIdpLogin";
import ForgotPassSendBlock from "./signInBlocks/forgotPassSendBlock";
import ForgotPassConfirmBlock from "./signInBlocks/forgotPassConfirm";
import ForgotPassThankYou from "./signInBlocks/forgotPassThankYou";
import {
	AUTH_STRINGS,
	MAX_PASSWORD_STRENGTH,
	EMAIL_VERIFICATION_REGEXP,
} from "../../const";
import { GoogleRegister } from "../GoogleRegister/GoogleRegister";
import { formatWhitespaces } from "../../utils";
import { PageContainer } from "../../components";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
	grid: {
		flexDirection: "column",
		gap: "15px",
		maxWidth: "427px",
		paddingBottom: "286px",
	},
	auth: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		padding: "25px",
		background: "#FFFFFF",
		border: "1px solid #DADADA",
		borderRadius: "4px",
	},
}));

const SignInContainer = () => {
	const { state, dispatch } = useStateContext();
	const classes = useStyles();
	const [userObj, setUserObj] = useState(emptyUserObj);
	const [forgotPassObj, setForgotPassObj] = useState(emptyForgotPassObj);
	const [awsError, setAWSError] = useState(false);
	const [loading, setLoading] = useState(false);
	const isColumn = useMediaQuery("(max-width:1136px)");

	const navigation = useHistory();
	const location = useLocation();

	//Clear local storage and erorrs
	useLayoutEffect(() => {
		setLoading(false);
		setAWSError(false);

		if (state.view === "FORGOT_PASS") {
			setForgotPassObj(emptyForgotPassObj);
			return;
		}

		setUserObj(emptyUserObj);
	}, [state?.view]);

	//Amplify functions to handle Sign in, Sign up, Forgot password

	async function signIn(email, password) {
		setLoading(true);
		try {
			const user = await Auth.signIn(email, password);
			if (
				user.challengeName ===
				AUTH_STRINGS.signIn.challengeName.newPassword
			) {
				navigation.push("/new-password" + location.search, { email });
				return;
			}
			console.log('Show loading screen after successfully sign in.');
			dispatch(setLoadingViewAction());
		} catch (error) {
			console.log('Got error when doing sign in.');
			console.log(error);
			setAWSError(true);
		} finally {
			setLoading(false);
		}
	}

	async function signUp(email, password, first_name, last_name, phone) {
		setLoading(true);
		try {
			await Auth.signUp({
				username: email,
				password: password,
				attributes: {
					family_name: last_name,
					given_name: first_name,
					...(phone && { phone_number: phone }),
				},
				autoSignIn: {
					enabled: true,
				},
			});
			console.log('Show loading screen after sign up.');
			dispatch(setLoadingViewAction());
		} catch (err) {
			if (
				err.code ===
				AUTH_STRINGS.signUp.cognitoError.errorCode.invalidParameter
			) {
				switch (err.message) {
					case AUTH_STRINGS.signUp.cognitoError.errorMessage
						.invalidPhone:
						setUserObj({
							...userObj,
							phone: { value: phone, showError: true },
						});
						break;
					case AUTH_STRINGS.signUp.cognitoError.errorMessage
						.invalidEmail:
						setUserObj({
							...userObj,
							email: { value: email, showError: true },
						});
						break;
					default:
						break;
				}
			}

			if (
				err.code ===
				AUTH_STRINGS.signUp.cognitoError.errorCode.userExists
			) {
				setUserObj({
					...userObj,
					email: { value: email, showError: true },
				});
			}
			console.log('Got error when doing sign up.');
			console.log(err);
			setAWSError(true);
		} finally {
			setLoading(false);
		}
	}

	async function forgotPassword(email) {
		setLoading(true);
		await Auth.forgotPassword(email)
			.then(() => {
				dispatch({
					type: "SET_VIEW",
					payload: "FORGOT_PASS_CONFIRM",
				});
			})
			.catch((err) => {
				console.log('Got error when doing Auth.forgotPassword(email).');
				console.log(err);
				setAWSError(true);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	async function forgotPasswordSubmit(email, code, newPassword) {
		setLoading(true);
		await Auth.forgotPasswordSubmit(email, code, newPassword)
			.then((user) => {
				dispatch({
					type: "SET_VIEW",
					payload: "FORGOT_PASS_THANKYOU",
				});
			})
			.catch((err) => {
				console.log('Got error when doing Auth.forgotPasswordSubmit().');
				console.log(err);
				setAWSError(true);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	//Handeling presentational changes in inputs, checkboxes, etc for user data and forgot password data (separetely)

	function handleUserObjChange(newValue, property) {
		setAWSError(false);
		setUserObj({
			...userObj,
			[`${property}`]: {
				value: newValue,
				...(property !== "phone" && { showError: false }),
			},
		});
	}

	function handleForgotPassObjChange(newValue, property) {
		setAWSError(false);
		setForgotPassObj({
			...forgotPassObj,
			passwordsMatch: {
				showError: false,
			},
			[`${property}`]: {
				value: newValue,
				showError: false,
			},
		});
	}

	function handleSubmit(type) {
		if (type === "SIGN_IN") {
			if (!userObj.email.value || !userObj.password.value) {
				console.log('Sign in with email or password');
				return setAWSError(true);
			}

			signIn(
				formatWhitespaces(userObj.email.value),
				formatWhitespaces(userObj.password.value)
			);
		}

		if (type === "SIGN_UP") {
			let modifiedUserObj = {};
			let filteredUserObj = {
				...userObj,
				email: {
					...userObj.email,
					value: formatWhitespaces(userObj.email.value),
				},
				first_name: {
					...userObj.first_name,
					value: formatWhitespaces(userObj.first_name.value),
				},
				last_name: {
					...userObj.last_name,
					value: formatWhitespaces(userObj.last_name.value),
				},
			};

			Object.entries(filteredUserObj).forEach(
				([keyUserObj, valueUserObj]) => {
					if (keyUserObj === "phone") {
						modifiedUserObj = {
							...modifiedUserObj,
							phone: { value: valueUserObj.value },
						};
						return;
					}

					let error = false;

					if (!valueUserObj.value) {
						error = true;
					}

					if (
						keyUserObj === "password" &&
						checkPasswordStrength(valueUserObj.value) !==
							MAX_PASSWORD_STRENGTH
					) {
						error = true;
					}

					if (
						keyUserObj === "email" &&
						!EMAIL_VERIFICATION_REGEXP.test(valueUserObj.value)
					) {
						error = true;
					}

					modifiedUserObj = {
						...modifiedUserObj,
						[`${keyUserObj}`]: {
							...valueUserObj,
							showError: error,
						},
					};
				}
			);
			setUserObj({ ...modifiedUserObj });
			if (
				!modifiedUserObj.email.showError &&
				!modifiedUserObj.password.showError &&
				!modifiedUserObj.first_name.showError &&
				!modifiedUserObj.last_name.showError &&
				!modifiedUserObj.toc.showError
			) {
				signUp(
					modifiedUserObj.email.value,
					modifiedUserObj.password.value,
					modifiedUserObj.first_name.value,
					modifiedUserObj.last_name.value,
					modifiedUserObj.phone.value
				);
			}
		}

		if (type === "FORGOT_PASS") {
			const inputEmail = formatWhitespaces(forgotPassObj.email.value);
			setForgotPassObj({
				...forgotPassObj,
				email: {
					value: inputEmail,
				},
			});
			forgotPassword(inputEmail);
		}

		if (type === "FORGOT_PASS_VERIFICATION") {
			let filteredPassObj = {};
			filteredPassObj = {
				email: { value: forgotPassObj.email.value },
				passwordsMatch: {
					...forgotPassObj.passwordsMatch,
					showError:
						forgotPassObj.newPassword.value !==
						forgotPassObj.newPasswordRepeated.value,
				},
				verificationCode: {
					...forgotPassObj.verificationCode,
					value: formatWhitespaces(
						forgotPassObj.verificationCode.value
					),
					showError: !formatWhitespaces(
						forgotPassObj.verificationCode.value
					)
						? true
						: false,
				},
			};

			Object.entries(forgotPassObj).forEach(
				([keyUserObj, valueUserObj]) => {
					if (
						keyUserObj === "email" ||
						keyUserObj === "passwordsMatch" ||
						keyUserObj === "verificationCode"
					) {
						return;
					}
					let error = false;

					if (
						!forgotPassObj[`${keyUserObj}`].value ||
						checkPasswordStrength(
							forgotPassObj[`${keyUserObj}`].value
						) !== MAX_PASSWORD_STRENGTH
					) {
						error = true;
					}

					filteredPassObj = {
						...filteredPassObj,
						[`${keyUserObj}`]: {
							...forgotPassObj[`${keyUserObj}`],
							showError: error,
						},
					};
				}
			);

			setForgotPassObj({
				...filteredPassObj,
			});

			if (
				!filteredPassObj.verificationCode.showError &&
				!filteredPassObj.newPassword.showError &&
				!filteredPassObj.newPasswordRepeated.showError &&
				!filteredPassObj.passwordsMatch.showError
			) {
				forgotPasswordSubmit(
					filteredPassObj.email.value,
					filteredPassObj.verificationCode.value,
					filteredPassObj.newPassword.value
				);
			}
		}
	}

	const forgotPassBlock = (
		<Box className={classes.auth}>
			{state.view === "FORGOT_PASS" && (
				<ForgotPassSendBlock
					forgotPassObj={forgotPassObj}
					handleForgotPassObjChange={handleForgotPassObjChange}
					loading={loading}
					handleSubmit={handleSubmit}
					awsError={awsError}
				/>
			)}
			{state.view === "FORGOT_PASS_CONFIRM" && (
				<ForgotPassConfirmBlock
					forgotPassObj={forgotPassObj}
					handleForgotPassObjChange={handleForgotPassObjChange}
					loading={loading}
					handleSubmit={handleSubmit}
					awsError={awsError}
				/>
			)}
			{state.view === "FORGOT_PASS_THANKYOU" && (
				<ForgotPassThankYou loading={loading} />
			)}
		</Box>
	);

	const handleSignUpViaGoogle = () => {
		try {
			handleIdpLogin("Google");
		} catch (error) {
			console.log('Got error when doing handleIdpLogin("Google").');
			console.log({ error });
		}
	};

	switch (state.view) {
		case "GOOGLE_SIGN_UP":
			console.log('Show GoogleRegister screen due to state.view=GOOGLE_SIGN_UP');
			return <GoogleRegister />;
		default:
			break;
	}

	return (
		<PageContainer
			additionalLogoSx={
				!isColumn &&
				state.view === "SIGN_IN" && {
					maxWidth: "1121px",
					width: "100%",
				}
			}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: state.view !== "SIGN_IN" ? "column" : "row",
					flexWrap: "wrap",
					justifyContent: "center",
					...(state.view !== "SIGN_IN" && {
						alignItems: "center",
					}),
				}}
			>
				<Box
					sx={{
						maxWidth: "694px",
						width: "100%",
						...((isColumn || state.view !== "SIGN_IN") && {
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							textAlign: "center",
							paddingBottom: "40px",
						}),
					}}
				>
					<Typography
						variant="h4"
						sx={{
							paddingTop: "19px",
							paddingBottom: "15px",
							fontSize: "37px",
							fontWeight: 600,
						}}
					>
						{state.view === "SIGN_IN" &&
							"Welcome to your 3T Account"}
						{state.view === "SIGN_UP" &&
							"Create your new 3T account"}
						{(state.view === "FORGOT_PASS" ||
							state.view === "FORGOT_PASS_CONFIRM" ||
							state.view === "FORGOT_PASS_THANKYOU") &&
							"Forgot your password?"}
					</Typography>
					<Typography
						sx={{
							maxWidth: "557px",
							fontSize:
								state.view === "SIGN_IN"
									? isColumn
										? "18px"
										: "20px"
									: "14px",
							paddingBottom: "10px",
						}}
					>
						{state.view === "SIGN_IN" &&
							`Access the full range of 3T services: activate your copy
						of Studio 3T, join our community, and manage your
						license.`}
						{state.view === "SIGN_UP" &&
							`Access Studio 3T and all 3T Software Labs’ services in just a few clicks.`}
					</Typography>
					{state.view === "SIGN_IN" && (
						<Typography
							sx={{
								maxWidth: "557px",
								fontSize: "14px",
							}}
						>
							Don’t have an account, yet?{" "}
							<Link
								onClick={() =>
									dispatch({
										type: "SET_VIEW",
										payload: "SIGN_UP",
									})
								}
							>
								Sign up.
							</Link>
						</Typography>
					)}
				</Box>
				<Grid container className={classes.grid}>
					<>
						{state.view === "SIGN_IN" && (
							<SignInBlock
								userObj={userObj}
								handleUserObjChange={handleUserObjChange}
								loading={loading}
								handleSubmit={handleSubmit}
								awsError={awsError}
							/>
						)}
						{state.view === "SIGN_UP" && (
							<SignUpBlock
								userObj={userObj}
								handleUserObjChange={handleUserObjChange}
								loading={loading}
								handleSubmit={handleSubmit}
								awsError={awsError}
							/>
						)}
						{(state.view === "SIGN_IN" ||
							state.view === "SIGN_UP") && (
							<Button
								sx={{
									justifyContent: "flex-start",
									backgroundColor: "#4285F6",
									height: "45px",
									paddingLeft: "8px",
									"&:hover": {
										backgroundColor: "#4285F6",
									},
								}}
								startIcon={<GoogleIcon />}
								variant={"contained"}
								onClick={handleSignUpViaGoogle}
							>
								Sign {state.view === "SIGN_IN" ? "in" : "up"}{" "}
								with Google
							</Button>
						)}
						{(state.view === "FORGOT_PASS" ||
							state.view === "FORGOT_PASS_CONFIRM" ||
							state.view === "FORGOT_PASS_THANKYOU") &&
							forgotPassBlock}
					</>
				</Grid>
			</Box>
		</PageContainer>
	);
};

export default SignInContainer;
