import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Amplify } from "aws-amplify";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import awsconfig from "./aws-exports";
import { QUERY_PARAMS_KEYS, STORAGE_KEYS } from "./const";

var Config = require("Config");

let amplifyConfig = {
	...awsconfig,
	Auth: {
		// OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
		authenticationFlowType:
			Config.authenticationFlowType !== undefined
				? Config.authenticationFlowType
				: "USER_SRP_AUTH",
	},
};

// If we have in parameter that means start a PKCE or Implict flow
// We switch the clientId to submit the one from the client website
let queryStringParams = new URLSearchParams(window.location.search);
let clientId = queryStringParams.get(QUERY_PARAMS_KEYS.clientID);
if (clientId) {
	// We save the client ID, our Amplify auth will be based on that one
	localStorage.setItem(STORAGE_KEYS.clientID, clientId);
} else {
	// If there is no client-Id in query, we set back the last one used for login
	// it may be undefined if we never logged in
	clientId = localStorage.getItem(STORAGE_KEYS.clientID);
}
if (clientId) {
	// We configure the Amplify Auth component in the context of using a client website client-id
	// if no clientId is found (direct login not from a client) the web client id of the broker will be used as default
	amplifyConfig.aws_user_pools_web_client_id = clientId;
}
Amplify.configure(amplifyConfig);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://create-react-app.dev/docs/making-a-progressive-web-app
serviceWorker.unregister();
