import { useState, useEffect, useMemo, useCallback } from "react";
import {
	Box,
	Typography,
	TextField,
	Link,
	Button,
	CircularProgress,
	Divider,
	OutlinedInput,
	InputAdornment,
	IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import makeStyles from "@mui/styles/makeStyles";

import { useStateContext } from "../../../context/StateContext";
import { FieldError } from "../../../components";
import checkPasswordStrength from "../../../hooks/checkPasswordStrength";
import { MAX_PASSWORD_STRENGTH } from "../../../const";

import { PersonalInfoBlock } from "./PersonalInfoBlock";

const useStyles = makeStyles((theme) => ({
	auth: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		padding: "25px",
		background: "#FFFFFF",
		border: "1px solid #DADADA",
		borderRadius: "4px",
	},
	heading: {
		fontWeight: 600,
		fontSize: "20px",
		lineHeight: "24px",
		paddingBottom: "20px",
	},
	pt15px: {
		paddingTop: "15px",
	},
	wrapper: {
		position: "relative",
	},
	buttonProgress: {
		color: theme.palette.primary.main,
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -4,
		marginLeft: -15,
	},
	divider: {
		width: "100%",
		paddingTop: "20px",
		paddingBottom: "20px",
	},
}));

function SignUpBlock({
	userObj,
	handleUserObjChange,
	loading,
	handleSubmit,
	awsError,
}) {
	const { email, password, first_name, last_name, toc, phone } = userObj;

	const classes = useStyles();
	const { dispatch } = useStateContext();
	const [showPassword, setShowPassword] = useState(false);
	const [focusedPasswordInput, setFocusedPasswordInput] = useState(false);
	const [showPasswordAdvice, setShowPasswordAdvice] = useState(false);

	const isErrorInInput = useMemo(
		() =>
			email.showError ||
			password.showError ||
			first_name.showError ||
			last_name.showError ||
			toc.showError ||
			phone.showError,
		[
			email.showError,
			first_name.showError,
			last_name.showError,
			password.showError,
			phone.showError,
			toc.showError,
		]
	);

	const handleClickShowPassword = useCallback(
		() => setShowPassword((show) => !show),
		[]
	);

	const handleMouseDownPassword = useCallback((event) => {
		event.preventDefault();
	}, []);

	const handleHitEnter = useCallback(
		(e) => {
			if (e.key === "Enter") {
				handleSubmit("SIGN_UP");
				e.preventDefault();
			}
		},
		[handleSubmit]
	);

	useEffect(() => {
		if (
			!password.showError &&
			focusedPasswordInput &&
			checkPasswordStrength(password.value) !== MAX_PASSWORD_STRENGTH
		) {
			setShowPasswordAdvice(true);
		} else {
			setShowPasswordAdvice(false);
		}
	}, [focusedPasswordInput, password]);

	const renderPasswordError = useCallback(() => {
		if (showPasswordAdvice) {
			return <FieldError field="password" styleType="info" />;
		}

		if (password.showError) {
			return <FieldError field="password" />;
		}
	}, [password, showPasswordAdvice]);

	const onKeyDown = useCallback((e) => handleHitEnter(e), [handleHitEnter]);

	return (
		<Box className={classes.auth}>
			<Typography className={classes.heading}>
				To sign up, please complete the form below:
			</Typography>
			<Typography>Email *</Typography>
			<TextField
				autoComplete="email"
				fullWidth
				variant="outlined"
				size="small"
				error={email.showError}
				value={email.value}
				onKeyDown={onKeyDown}
				onChange={(e) =>
					handleUserObjChange(e.currentTarget.value, "email")
				}
				placeholder="Type your email address here"
				type="email"
			/>
			{email.showError && <FieldError field="email" />}
			<Typography className={classes.pt15px}>Password *</Typography>
			<Box sx={{ position: "relative", width: "100%" }}>
				<OutlinedInput
					autoComplete="new-password"
					fullWidth
					type={showPassword ? "text" : "password"}
					size="small"
					error={password.showError}
					onBlur={() => {
						setFocusedPasswordInput(false);
					}}
					onFocus={() => setFocusedPasswordInput(true)}
					onKeyDown={onKeyDown}
					value={password.value}
					onChange={(e) => {
						handleUserObjChange(e.currentTarget.value, "password");
					}}
					placeholder="Type your password here"
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
								edge="end"
							>
								{showPassword ? (
									<VisibilityOff />
								) : (
									<Visibility />
								)}
							</IconButton>
						</InputAdornment>
					}
				/>

				{renderPasswordError()}
			</Box>

			<PersonalInfoBlock
				onKeyDown={onKeyDown}
				first_name={first_name}
				last_name={last_name}
				phone={phone}
				toc={toc}
				handleUserObjChange={handleUserObjChange}
				className={classes.pt15px}
			/>

			<div className={classes.wrapper} key={"sign_up"}>
				<Button
					disabled={loading}
					sx={{
						marginTop: "15px",
					}}
					variant={"contained"}
					onClick={() => {
						handleSubmit("SIGN_UP");
					}}
				>
					Sign up
				</Button>
				{loading && (
					<CircularProgress
						size={24}
						className={classes.buttonProgress}
					/>
				)}
			</div>
			{isErrorInInput && <FieldError field="generic" />}
			{awsError && !isErrorInInput && (
				<FieldError field="sign_up_error" />
			)}
			<Box className={classes.divider}>
				<Divider />
			</Box>
			<Typography>
				Already have an account?{" "}
				<Link
					onClick={() =>
						dispatch({
							type: "SET_VIEW",
							payload: "SIGN_IN",
						})
					}
				>
					Sign in
				</Link>
			</Typography>
		</Box>
	);
}

export default SignUpBlock;
