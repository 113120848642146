import { useStateContext } from "../../../context/StateContext";
import {
	Box,
	Typography,
	TextField,
	Link,
	Button,
	CircularProgress,
	Divider,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { FieldError } from "../../../components";

const useStyles = makeStyles((theme) => ({
	auth: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		padding: "25px",
		background: "#FFFFFF",
		border: "1px solid #DADADA",
		borderRadius: "4px",
	},
	heading: {
		fontWeight: 600,
		fontSize: "20px",
		lineHeight: "24px",
		paddingBottom: "20px",
	},
	pt15px: {
		paddingTop: "15px",
	},
	wrapper: {
		position: "relative",
	},
	buttonProgress: {
		color: theme.palette.primary.main,
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -4,
		marginLeft: -15,
	},
	divider: {
		width: "100%",
		paddingTop: "20px",
		paddingBottom: "20px",
	},
}));

function SignInBlock({
	userObj,
	handleUserObjChange,
	loading,
	handleSubmit,
	awsError,
}) {
	const { dispatch } = useStateContext();
	const classes = useStyles();

	function handleHitEnter(e) {
		if (e.key === "Enter") {
			handleSubmit("SIGN_IN");
			e.preventDefault();
		}
	}
	return (
		<Box className={classes.auth}>
			<Typography className={classes.heading}>
				Sign in with your email and password
			</Typography>
			<Typography>Email</Typography>
			<TextField
				autoComplete="email"
				fullWidth
				variant="outlined"
				size="small"
				placeholder="Type your email address here"
				value={userObj.email.value}
				onKeyDown={(e) => handleHitEnter(e)}
				onChange={(e) =>
					handleUserObjChange(e.currentTarget.value, "email")
				}
			/>
			<Typography className={classes.pt15px}>Password</Typography>
			<TextField
				autoComplete="password"
				fullWidth
				variant="outlined"
				size="small"
				placeholder="Type your password here"
				type="password"
				value={userObj.password.value}
				onKeyDown={(e) => handleHitEnter(e)}
				onChange={(e) =>
					handleUserObjChange(e.currentTarget.value, "password")
				}
			/>
			<Typography className={classes.pt15px}>
				<Link
					onClick={() =>
						dispatch({
							type: "SET_VIEW",
							payload: "FORGOT_PASS",
						})
					}
				>
					Forgot your password?
				</Link>
			</Typography>

			<div className={classes.wrapper}>
				<Button
					disabled={loading}
					sx={{
						marginTop: "15px",
					}}
					variant={"contained"}
					onClick={() => {
						handleSubmit("SIGN_IN");
					}}
				>
					Sign in
				</Button>
				{loading && (
					<CircularProgress
						size={24}
						className={classes.buttonProgress}
					/>
				)}
			</div>
			{awsError && <FieldError field="sign_in_error" />}
			<Box className={classes.divider}>
				<Divider />
			</Box>
			<Typography>
				Need an account?{" "}
				<Link
					onClick={() =>
						dispatch({
							type: "SET_VIEW",
							payload: "SIGN_UP",
						})
					}
				>
					Sign up
				</Link>
			</Typography>
		</Box>
	);
}

export default SignInBlock;
