import { useState, useCallback, useMemo } from "react";
import { Auth } from "aws-amplify";
import {
	Box,
	Button,
	Grid,
	Typography,
	CircularProgress,
	IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";

import {
	useStateContext,
	setLoadingViewAction,
} from "../../context/StateContext";
import { emptyPersonalInfoObj } from "../../placeholders/userobj";
import { PersonalInfoBlock } from "../LandingPage/signInBlocks/PersonalInfoBlock";
import { FieldError, PageContainer } from "../../components";
import handleAuthUIStateChange from "../../hooks/handleAuthStateChange";
import { formatWhitespaces } from "../../utils";
import { AUTH_STRINGS, QUERY_PARAMS_KEYS, STORAGE_KEYS } from "../../const";
import { CancelRegistrationModal } from "./CancelRegistrationModal";

const useStyles = makeStyles((theme) => ({
	auth: {
		position: "relative",
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		padding: "25px",
		background: "#FFFFFF",
		border: "1px solid #DADADA",
		borderRadius: "4px",
	},
	heading: {
		fontWeight: 600,
		fontSize: "20px",
		lineHeight: "24px",
		paddingBottom: "5px",
	},
	grid: {
		flexDirection: "column",
		gap: "15px",
		maxWidth: "427px",
		paddingBottom: "286px",
	},
	pt15px: {
		paddingTop: "15px",
	},
	submitContainer: {
		paddingTop: "15px",
		position: "relative",
	},
	buttonProgress: {
		color: theme.palette.primary.main,
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -4,
		marginLeft: -15,
	},
	closeButton: {
		position: "absolute",
		right: 13,
		top: 14,
	},
	innerContainer: {
		display: "flex",
		flexDirection: "column",
		flexWrap: "wrap",
		justifyContent: "center",
		alignItems: "center",
	},
}));

export const GoogleRegister = () => {
	const classes = useStyles();
	const [userObj, setUserObj] = useState(emptyPersonalInfoObj);
	const [loading, setLoading] = useState(false);

	const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);

	const { dispatch } = useStateContext();

	const isErrorInInput = useMemo(
		() =>
			userObj.first_name.showError ||
			userObj.last_name.showError ||
			userObj.toc.showError ||
			userObj.phone.showError,
		[userObj]
	);

	const handleUserObjChange = useCallback((newValue, property) => {
		setUserObj((prevState) => ({
			...prevState,
			[`${property}`]: {
				value: newValue,
				...(property !== "phone" && { showError: false }),
			},
		}));
	}, []);

	const handleSubmit = useCallback(async () => {
		setLoading(true);

		let modifiedUserObj = {};
		let filteredUserObj = {
			...userObj,
			first_name: {
				...userObj.first_name,
				value: formatWhitespaces(userObj.first_name.value),
			},
			last_name: {
				...userObj.last_name,
				value: formatWhitespaces(userObj.last_name.value),
			},
		};

		Object.entries(filteredUserObj).forEach(
			([keyUserObj, valueUserObj]) => {
				if (keyUserObj === "phone") {
					modifiedUserObj = {
						...modifiedUserObj,
						phone: { value: valueUserObj.value },
					};
					return;
				}

				let error = false;

				if (!valueUserObj.value) {
					error = true;
				}

				modifiedUserObj = {
					...modifiedUserObj,
					[`${keyUserObj}`]: {
						...valueUserObj,
						showError: error,
					},
				};
			}
		);

		if (
			!modifiedUserObj.first_name.showError &&
			!modifiedUserObj.last_name.showError &&
			!modifiedUserObj.toc.showError
		) {
			try {
				const authenticatedUser = await Auth.currentAuthenticatedUser();
				await Auth.updateUserAttributes(authenticatedUser, {
					"custom:contactNeeded": "false",
					family_name: modifiedUserObj.last_name.value,
					given_name: modifiedUserObj.first_name.value,
					...(modifiedUserObj.phone && {
						phone_number: modifiedUserObj.phone.value,
					}),
				});
				await handleAuthUIStateChange(Boolean(authenticatedUser));
				console.log('Show loading screen after updateUserAttributes from GoogleRegister.');
				dispatch(setLoadingViewAction());
			} catch (error) {
				if (
					error.code ===
					AUTH_STRINGS.signUp.cognitoError.errorCode.invalidParameter
				) {
					if (
						error.message ===
						AUTH_STRINGS.signUp.cognitoError.errorMessage
							.invalidPhone
					) {
						modifiedUserObj = {
							...modifiedUserObj,
							phone: {
								value: modifiedUserObj.phone.value,
								showError: true,
							},
						};
					}
				}
				console.log('Got error in GoogleRegister.');
				console.log(error);
			}
		}
		setUserObj({ ...modifiedUserObj });
		setLoading(false);
	}, [dispatch, userObj]);

	const handleHitEnter = useCallback(
		(e) => {
			if (e.key === "Enter") {
				handleSubmit();
				e.preventDefault();
			}
		},
		[handleSubmit]
	);

	const onKeyDown = useCallback((e) => handleHitEnter(e), [handleHitEnter]);

	const toggleCloseModal = useCallback(() => {
		setIsCloseModalOpen((open) => !open);
	}, []);

	const onCancelRegistration = useCallback(async () => {
		const redirect_uri = localStorage.getItem(STORAGE_KEYS.originURI);
		const client_id = localStorage.getItem(STORAGE_KEYS.clientID);

		const searchParams = new URLSearchParams();
		searchParams.set(QUERY_PARAMS_KEYS.logoutURI, redirect_uri);
		searchParams.set(QUERY_PARAMS_KEYS.clientID, client_id);

		window.location.replace(
			`${window.location.origin}/logout?${searchParams.toString()}`
		);
	}, []);

	return (
		<PageContainer>
			<Box className={classes.innerContainer}>
				<Box
					sx={{
						maxWidth: "694px",
						width: "100%",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						textAlign: "center",
						paddingBottom: "40px",
					}}
				>
					<Typography
						variant="h4"
						sx={{
							paddingTop: "19px",
							paddingBottom: "15px",
							fontSize: "37px",
							fontWeight: 600,
						}}
					>
						{"Almost there!"}
					</Typography>
				</Box>
				<Grid container className={classes.grid}>
					<Box className={classes.auth}>
						<IconButton
							className={classes.closeButton}
							onClick={toggleCloseModal}
						>
							<Close />
						</IconButton>

						<Typography className={classes.heading}>
							{
								"To complete your registration, please complete the following information:"
							}
						</Typography>
						<PersonalInfoBlock
							onKeyDown={onKeyDown}
							first_name={userObj.first_name}
							last_name={userObj.last_name}
							phone={userObj.phone}
							toc={userObj.toc}
							handleUserObjChange={handleUserObjChange}
							className={classes.pt15px}
						/>
						<Box className={classes.submitContainer}>
							<Button
								disabled={loading}
								variant={"contained"}
								onClick={handleSubmit}
							>
								{"Sign up"}
							</Button>
							{loading && (
								<CircularProgress
									size={24}
									className={classes.buttonProgress}
								/>
							)}
						</Box>
						{isErrorInInput && <FieldError field="generic" />}
					</Box>
				</Grid>
			</Box>
			<CancelRegistrationModal
				isOpen={isCloseModalOpen}
				toggleOpen={toggleCloseModal}
				onConfirm={onCancelRegistration}
			/>
		</PageContainer>
	);
};
