import React, { useReducer, useContext, createContext } from "react";

const StateContext = createContext();

const initialState = {
	view: "SIGN_IN",
};

/**
 * Action creator.
 * @param {string} view null | SIGN_IN | FORGOT_PASS | FORGOT_PASS_CONFIRM | FORGOT_PASS_VERIFICATION | FORGOT_PASS_THANKYOU | SIGN_UP | GOOGLE_SIGN_UP
 */
export const setViewAction = (view) => {
	return {
		type: "SET_VIEW",
		payload: view,
	};
};

export const setLoadingViewAction = () => {
	return {
		type: "SET_VIEW",
		payload: null,
	};
};

function reducer(state, action) {
	switch (action.type) {
		case "SET_VIEW":
			return {
				...state,
				view: action.payload,
			};
		default:
			return {
				...state,
			};
	}
}

export function StateProvider({ children }) {
	const [state, dispatch] = useReducer(reducer, initialState);
	return (
		<StateContext.Provider
			value={{
				state,
				dispatch,
			}}
		>
			{children}
		</StateContext.Provider>
	);
}

export function useStateContext() {
	const context = useContext(StateContext);
	if (context === undefined) {
		throw Error("State Context must be used within a Provider");
	}
	return context;
}
