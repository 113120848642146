import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { theme } from "./branding";
import { StateProvider } from "./context/StateContext";
import LandingPage from "./pages/LandingPage/LandingPage";
import Logout from "./pages/Logout/Logout";
import { NewPasswordPage } from "./pages/NewPasswordPage";
import { AppLogout } from "./pages/AppLogout/AppLogout";
import "./index.css";

function App() {
	return (
		<div className="App">
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<StateProvider>
						<BrowserRouter>
							<Switch>
								<Route exact path="/app-logout">
									<AppLogout />
								</Route>
								<Route exact path="/logout">
									<Logout />
								</Route>
								<Route exact path="/new-password">
									<NewPasswordPage />
								</Route>
								<Route path="/">
									<LandingPage />
								</Route>
							</Switch>
						</BrowserRouter>
					</StateProvider>
				</ThemeProvider>
			</StyledEngineProvider>
		</div>
	);
}

export default App;
