import { useStateContext } from "../../../context/StateContext";
import { Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
	heading: {
		fontWeight: 600,
		fontSize: "20px",
		lineHeight: "24px",
		paddingBottom: "20px",
	},
}));

function ForgotPassThankYou({ loading }) {
	const { dispatch } = useStateContext();
	const classes = useStyles();

	return (
		<>
			<Typography className={classes.heading}>Thank you</Typography>
			<Typography>Your password has been successfully reset.</Typography>

			<Button
				disabled={loading}
				sx={{
					marginTop: "15px",
				}}
				variant={"contained"}
				onClick={() => {
					dispatch({
						type: "SET_VIEW",
						payload: "SIGN_IN",
					});
				}}
			>
				Back to the sign in page
			</Button>
		</>
	);
}

export default ForgotPassThankYou;
