/**
 *
 * @param {string | null} view Name of Screen View
 * @param {string | null} authorization_code Authorization Code URL Param
 * @param {boolean | null} isAuthenticated Is User Authenticated
 * @returns {boolean}
 */

export const screenConditionCheck = (
	view,
	authorization_code,
	isAuthenticated
) => {
	if (!view) {
		/**
		 * When no screen, we show loading screen.
		 */
		return false;
	}

	if (!authorization_code) {
		/**
		 * When no authorization_code, we show loading screen.
		 */
		return false;
	}

	if (isAuthenticated && (view !== "GOOGLE_SIGN_UP")) {
		/**
		 * When user authenticated, but token refreshing. We should show loading screen.
		 */
		return false;
	}

	if (isAuthenticated == null) {
		/**
		 * Initial state of isAuthenticated prop.
		 * This prevents cases where the authentication check is still in progress, but the login screen is visible.
		 */
		return false;
	}

	return true;
};
