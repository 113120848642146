const emptyForgotPassObj = {
	email: {
		value: "",
	},
	verificationCode: {
		value: "",
		showError: false,
	},
	newPassword: {
		value: "",
		showError: false,
	},
	newPasswordRepeated: {
		value: "",
		showError: false,
	},
	passwordsMatch: {
		showError: false,
	},
};

export default emptyForgotPassObj;
