import React, { useCallback } from "react";
import {
	Typography,
	TextField,
	FormControlLabel,
	Checkbox,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";

import { FieldError } from "../../../components";
import { PolicyAndTermsBlock } from "../PolicyAndTermsBlock";

import { MAX_PHONE_DIGITS } from "../../../const";

export const PersonalInfoBlock = ({
	onKeyDown,
	first_name,
	last_name,
	phone,
	toc,
	handleUserObjChange,
	className,
}) => {
	const handleFirstNameChange = useCallback(
		(e) => {
			handleUserObjChange(e.currentTarget.value, "first_name");
		},
		[handleUserObjChange]
	);
	const handleLastNameChange = useCallback(
		(e) => {
			handleUserObjChange(e.currentTarget.value, "last_name");
		},
		[handleUserObjChange]
	);

	const handlePhoneNumberChange = useCallback(
		(newValue, info) => {
			if (
				!info.numberValue?.length ||
				info.numberValue.length <= MAX_PHONE_DIGITS
			) {
				handleUserObjChange(info.numberValue, "phone");
			}
		},
		[handleUserObjChange]
	);

	const handleTocChange = useCallback(
		(e) => {
			handleUserObjChange(e.target.checked, "toc");
		},
		[handleUserObjChange]
	);
	return (
		<>
			<Typography className={className}>First name *</Typography>
			<TextField
				autoComplete="given-name"
				fullWidth
				variant="outlined"
				size="small"
				error={first_name.showError}
				value={first_name.value}
				onKeyDown={onKeyDown}
				onChange={handleFirstNameChange}
				placeholder="Type your first name here"
				inputProps={{ "aria-label": "given-name" }}
			/>
			{first_name.showError && <FieldError />}
			<Typography className={className}>Last name *</Typography>
			<TextField
				autoComplete="family-name"
				fullWidth
				variant="outlined"
				size="small"
				error={last_name.showError}
				value={last_name.value}
				onKeyDown={onKeyDown}
				onChange={handleLastNameChange}
				placeholder="Type your last name here"
				inputProps={{ "aria-label": "family-name" }}
			/>
			{last_name.showError && <FieldError />}
			<Typography className={className}>Telephone number</Typography>
			<MuiTelInput
				autoComplete="tel"
				fullWidth
				size="small"
				defaultCountry="US"
				error={phone.showError}
				value={phone.value}
				onKeyDown={onKeyDown}
				onChange={handlePhoneNumberChange}
				inputProps={{ "aria-label": "phone-number" }}
			/>
			{phone.showError && <FieldError field="phone" />}
			<FormControlLabel
				className={className}
				control={
					<Checkbox
						checked={toc.value || false}
						onChange={handleTocChange}
						inputProps={{ "aria-label": "toc" }}
					/>
				}
				label={<PolicyAndTermsBlock />}
			/>
			{toc.showError && <FieldError field="toc" />}
		</>
	);
};
