import React, { useCallback, useRef, useState } from "react";
import {
	Box,
	FormControl,
	Typography,
	Link,
	Button,
	CircularProgress,
	Divider,
	OutlinedInput,
	InputAdornment,
	IconButton,
	FormLabel,
	useFormControl,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useHistory, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";

import { AUTH_STRINGS } from "../../const";
import {
	useStateContext,
	setLoadingViewAction,
} from "../../context/StateContext";
import { FieldError, PageContainer } from "../../components";
import checkPasswordStrength from "../../hooks/checkPasswordStrength";
import handleAuthUIStateChange from "../../hooks/handleAuthStateChange";

const useStyles = makeStyles((theme) => ({
	heading: {
		fontSize: "37px",
		fontWeight: 600,
		paddingBottom: "15px",
		paddingTop: "19px",
	},
	pt15px: {
		paddingTop: "15px",
	},
	buttonWrapper: {
		marginTop: "15px",
		position: "relative",
	},
	buttonProgress: {
		color: theme.palette.primary.main,
		left: "50%",
		marginLeft: -15,
		marginTop: -10,
		position: "absolute",
		top: "50%",
	},
	divider: {
		paddingBottom: "20px",
		paddingTop: "20px",
		width: "100%",
	},
	innerContainer: {
		alignItems: "center",
		display: "flex",
		flex: 1,
		flexDirection: "column",
		justifyContent: "center",
	},
	form: {
		display: "flex",
		flexDirection: "column",
		gap: "15px",
		maxWidth: "427px",
		padding: "50px 0",
		width: "100%",
	},
	formTitle: {
		fontSize: "20px",
		fontWeight: 600,
		lineHeight: "24px",
		paddingBottom: "20px",
	},
	innerFormContainer: {
		alignItems: "flex-start",
		background: "#FFFFFF",
		border: "1px solid #DADADA",
		borderRadius: "4px",
		display: "flex",
		flexDirection: "column",
		padding: "25px",
	},
}));

const FormFieldError = () => {
	const { focused, error } = useFormControl();
	if (error || focused) {
		return (
			<FieldError field="password" styleType={focused ? "info" : null} />
		);
	}
	return null;
};

export const NewPasswordPage = () => {
	const classes = useStyles();
	const navigation = useHistory();
	const { state } = useLocation();

	const { dispatch } = useStateContext();

	const [showPassword, setShowPassword] = useState(false);
	const [showOldPassword, setShowOldPassword] = useState(false);
	const [showRepeatedPassword, setShowRepeatedPassword] = useState(false);
	const [loading, setLoading] = useState(false);

	const [errorOldPassword, setErrorOldPassword] = useState(false);
	const [error, setError] = useState(false);

	const formRef = useRef(null);

	const isPasswordsDifferent = useCallback(
		() => formRef.current[3].value !== formRef.current[6].value,
		[]
	);

	const isAnyFieldEpmty = useCallback(
		() => !formRef.current[3].value || !formRef.current[6].value,
		[]
	);

	const handleGoBack = useCallback(() => {
		navigation.goBack();
	}, [navigation]);

	const handleSubmit = useCallback(
		async (event) => {
			setLoading(true);
			event.preventDefault();
			const oldPassword = formRef.current[0].value;
			const newPassword = formRef.current[3].value;
			const repeatedPassword = formRef.current[6].value;

			try {
				if (isPasswordsDifferent() || isAnyFieldEpmty()) {
					throw Error("passwords different");
				}
				if (checkPasswordStrength(newPassword) !== 4) {
					throw Error("weak password");
				}
				if (checkPasswordStrength(repeatedPassword) !== 4) {
					throw Error("weak password");
				}
				const user = await Auth.signIn(state.email, oldPassword);
				const loggedInUser = await Auth.completeNewPassword(
					user,
					newPassword
				);
				await handleAuthUIStateChange(Boolean(loggedInUser));
				dispatch(setLoadingViewAction());
			} catch (err) {
				console.log('Got error in NewPasswordPage.');
				console.log(err);
				if (err?.message) {
					if (
						err.message ===
						AUTH_STRINGS.signIn.cognitoError.errorMessage
							.incorrectUsernameOrPassword
					) {
						setErrorOldPassword(true);
						return;
					}
				}
				setError(true);
			} finally {
				setLoading(false);
			}
		},
		[dispatch, isAnyFieldEpmty, isPasswordsDifferent, state.email]
	);

	const resetErrorPassword = useCallback(() => {
		setError(false);
	}, []);

	const resetErrorOldPassword = useCallback(() => {
		setErrorOldPassword(false);
	}, []);

	const toggleShowPassword = useCallback(() => {
		setShowPassword((show) => !show);
	}, []);

	const toggleShowOldPassword = useCallback(() => {
		setShowOldPassword((show) => !show);
	}, []);

	const toggleShowRepeatedPassword = useCallback(() => {
		setShowRepeatedPassword((show) => !show);
	}, []);

	return (
		<PageContainer>
			<Box className={classes.innerContainer}>
				<Typography className={classes.heading} variant="h4">
					{"Change your password"}
				</Typography>
				<form
					ref={formRef}
					className={classes.form}
					onSubmit={handleSubmit}
					name="new-password-form"
				>
					<Box className={classes.innerFormContainer}>
						<Typography className={classes.formTitle}>
							{"Please enter your password"}
						</Typography>
						<FormControl fullWidth error={errorOldPassword}>
							<FormLabel>{"Old password *"}</FormLabel>
							<OutlinedInput
								name="password"
								autoComplete="password"
								fullWidth
								type={showOldPassword ? "text" : "password"}
								size="small"
								onKeyDown={resetErrorOldPassword}
								required
								placeholder="Type your old password here"
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											onClick={toggleShowOldPassword}
											edge="end"
										>
											{showOldPassword ? (
												<VisibilityOff />
											) : (
												<Visibility />
											)}
										</IconButton>
									</InputAdornment>
								}
							/>
						</FormControl>
						<FormControl
							fullWidth
							error={error}
							className={classes.pt15px}
						>
							<FormLabel>{"New password *"}</FormLabel>
							<OutlinedInput
								name="new-password"
								autoComplete="new-password"
								fullWidth
								type={showPassword ? "text" : "password"}
								size="small"
								onKeyDown={resetErrorPassword}
								required
								placeholder="Type your new password here"
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											onClick={toggleShowPassword}
											edge="end"
										>
											{showPassword ? (
												<VisibilityOff />
											) : (
												<Visibility />
											)}
										</IconButton>
									</InputAdornment>
								}
							/>
							<FormFieldError />
						</FormControl>
						<FormControl
							fullWidth
							className={classes.pt15px}
							error={error}
						>
							<FormLabel>
								{"Enter new password again *"}
							</FormLabel>
							<OutlinedInput
								name="repeat-new-password"
								autoComplete="new-password"
								fullWidth
								type={
									showRepeatedPassword ? "text" : "password"
								}
								size="small"
								required
								onKeyDown={resetErrorPassword}
								placeholder="Type your new password here again"
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											onClick={toggleShowRepeatedPassword}
											edge="end"
										>
											{showRepeatedPassword ? (
												<VisibilityOff />
											) : (
												<Visibility />
											)}
										</IconButton>
									</InputAdornment>
								}
							/>
							<FormFieldError />
						</FormControl>

						<Box className={classes.buttonWrapper}>
							<Button
								disabled={loading}
								variant={"contained"}
								type="submit"
								onClick={handleSubmit}
							>
								{"Send"}
							</Button>
							{loading && (
								<CircularProgress
									size={24}
									className={classes.buttonProgress}
								/>
							)}
						</Box>
						{errorOldPassword && (
							<FieldError field="sign_in_error" />
						)}
						{error && isPasswordsDifferent() && (
							<FieldError field="forgot_pass_passwords_not_matched" />
						)}
						<Box className={classes.divider}>
							<Divider />
						</Box>
						<Typography>
							<Link onClick={handleGoBack}>
								{"Back to Sign In Page"}
							</Link>
						</Typography>
					</Box>
				</form>
			</Box>
		</PageContainer>
	);
};
