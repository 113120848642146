export const emptyPersonalInfoObj = {
	first_name: {
		value: "",
		showError: false,
	},
	last_name: {
		value: "",
		showError: false,
	},
	toc: {
		value: false,
		showError: false,
	},
	phone: {
		value: "",
		showError: false,
	},
};

const emptyUserObj = {
	email: {
		value: "",
		showError: false,
	},
	password: {
		value: "",
		showError: false,
	},
	...emptyPersonalInfoObj,
};

export default emptyUserObj;
