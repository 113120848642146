import React from "react";
import { Box, Fade } from "@mui/material";
import { makeStyles } from "@mui/styles";

import whirls from "../../assets/background-whirls.png";
import Studio3TLogo from "../../assets/Studio3TLogo";

const useStyles = makeStyles((theme) => ({
	container: {
		flexDirection: "column",
		gap: "15px",
		maxWidth: "427px",
		paddingBottom: "286px",
	},
	whirls: {
		height: "100%",
		padding: "0 20px",
		backgroundImage: `url(${whirls})`,
		"background-position-y": "120px",
		"background-position-x": "center",
		backgroundRepeat: "no-repeat",
		backgroundSize: "1440px 488px",
	},
}));

export const PageContainer = ({ children, additionalLogoSx }) => {
	const classes = useStyles();
	return (
		<Box className={classes.whirls}>
			<Fade in timeout={300}>
				<Box>
					<Box display="flex" justifyContent="center">
						<Box
							sx={{
								height: "109px",
								paddingTop: "20px",
								...additionalLogoSx,
							}}
						>
							<Studio3TLogo />
						</Box>
					</Box>
					{children}
				</Box>
			</Fade>
		</Box>
	);
};
