export default function GoogleIcon() {
	return (
		<div
			style={{
				backgroundColor: "white",
				height: "37px",
				width: "37px",
				borderRadius: "2px",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<svg
				width="26"
				height="25"
				viewBox="0 0 26 25"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M24.8384 10.0519H23.8292V10H12.5528V15H19.6337C18.6007 17.9106 15.8248 20 12.5528 20C8.40118 20 5.03517 16.6419 5.03517 12.5C5.03517 8.35812 8.40118 5 12.5528 5C14.4691 5 16.2126 5.72125 17.5401 6.89937L21.084 3.36375C18.8463 1.28312 15.853 0 12.5528 0C5.63345 0 0.0234375 5.59688 0.0234375 12.5C0.0234375 19.4031 5.63345 25 12.5528 25C19.4721 25 25.0821 19.4031 25.0821 12.5C25.0821 11.6619 24.9957 10.8438 24.8384 10.0519Z"
					fill="#FFC107"
				/>
				<path
					d="M1.46802 6.68188L5.58453 9.69375C6.69839 6.9425 9.39596 5 12.5527 5C14.4691 5 16.2125 5.72125 17.54 6.89938L21.084 3.36375C18.8462 1.28313 15.853 0 12.5527 0C7.74021 0 3.56668 2.71063 1.46802 6.68188Z"
					fill="#FF3D00"
				/>
				<path
					d="M12.5528 24.9999C15.7891 24.9999 18.7298 23.7643 20.9531 21.7549L17.0753 18.4812C15.8173 19.4318 14.2537 19.9999 12.5528 19.9999C9.29393 19.9999 6.52682 17.9268 5.48438 15.0337L1.39856 18.1743C3.47217 22.2224 7.68328 24.9999 12.5528 24.9999Z"
					fill="#4CAF50"
				/>
				<path
					d="M24.8385 10.0519H23.8293V10H12.5529V15H19.6338C19.1377 16.3981 18.2362 17.6038 17.0734 18.4819L17.0753 18.4806L20.9532 21.7544C20.6788 22.0031 25.0822 18.75 25.0822 12.5C25.0822 11.6619 24.9957 10.8438 24.8385 10.0519Z"
					fill="#1976D2"
				/>
			</svg>
		</div>
	);
}
